@use '../../variables'

.modal
    display: flex
    align-items: center
    justify-content: center
    position: fixed
    z-index: 1
    left: 0
    top: 0
    width: 100%
    height: 100%
    background-color: rgba(0,0,0,0.75)

    .modalContainer
        width: 440px
        display: flex
        flex-direction: column

        .closeButton
            display: flex
            justify-content: flex-end

            svg
                color: variables.$interactable-color
                cursor: pointer

                &:hover
                    color: variables.$interactable-hover

        .modalContent
            padding: 24px 16px
            background-color: #fefefe

            .title
                font-size: 24px
                color: #060607
                text-align: center
                font-weight: bold

            .description
                margin-top: 8px
                text-align: center
                color: #4F5660

            .fileInputContainer
                margin-top: 16px
                text-align: center

            .nameInputContainer
                display: flex
                flex-direction: column

                label
                    font-size: 12px
                    color: #6A7480
                    font-weight: bold

                input
                    margin-top: 8px
                    font-size: 16px
                    padding: 10px
                    transition: all 0.2 ease-in-out

                    &:focus
                        outline-color: #a6b5e7
        .modalFooter
            display: flex
            justify-content: flex-end
            background-color: #F6F6F7
            padding: 12px 16px

            .button
                display: flex
                background-color: #7289DA
                color: white
                padding: 8px 24px
                border-radius: 3px
                cursor: pointer
