@use '../../variables'

.container
    display: flex
    flex-direction: column
    background-color: variables.$primary-color
    color: variables.$text-color
    width: 100%
    height: 100%
    max-height: 100vh

    .content
        display: flex
        flex-direction: column
        height: 100%

        .chatContainer
            display: flex
            flex-direction: column-reverse
            overflow-y: scroll
            height: 100%
            max-height: calc( 100vh - 115px)
            padding-bottom: 16px
