@use '../../variables'

.container
    display: flex
    padding: 0px 20px
    margin-top: 16px
    position: relative

    &:hover
        background-color: variables.$fourth-hover-color

        .buttons
            display: flex

    img
        height: 40px
        width: 40px
        border-radius: 50%

    .content
        display: flex
        flex-direction: column
        margin: 0px 16px

        .header
            display: flex
            align-items: flex-end

            .name
                color: white
                cursor: pointer

                &:hover
                    text-decoration: underline

            .time
                margin-left: 8px
                font-size: variables.$font-small
                color: variables.$text-color

        .message
            display: flex
            color: variables.$secondary-text-color
            width: 100%
            word-wrap: break-word
            word-break: break-all
            padding-right: 20px

.buttons
    top: -16px
    right: 40px
    position: absolute
    width: 96px
    height: 32px
    background-color: variables.$primary-color
    box-shadow: 0 0 0 1px rgba(4,4,5,0.15)
    display: none
    align-items: center

    .button
        display: flex
        align-items: center
        justify-content: center
        width: 32px
        height: 32px
        cursor: pointer

        &:hover
            color: variables.$text-hover
            background-color: variables.$secondary-hover-color

        &:last-child
            color: red

            &:hover
                color: white
                background-color: red
