$navbar-color: #202225
$primary-color: #36393f
$secondary-color: #2f3136
$third-color: #292b2f
$fourth-color: #40444b

$white-color: #dcddde
$blue-color: #7289da
$green-color: #43b581

$divider-color: #2d2f32

$text-color: #8a8c8c
$secondary-text-color: #dcddde
$text-hover: #dcddde

$hover-color: #34373c
$secondary-hover-color: #3a3e44
$third-hover-color: #3e4248
$fourth-hover-color: #32353b

$divider-color: #3f4247

$interactable-color: #b9bbbe
$interactable-hover: #dcddde

$font: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
$font-small: 12px
$font-medium: 16px

$content-nav-height: 45px
