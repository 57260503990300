@use '../../../../variables'

.btn
    display: flex
    flex-direction: row
    padding: 4px 8px
    text-decoration: none
    color: variables.$text-color
    align-items: center
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap

    &:hover
        background-color: variables.$hover-color
        color: variables.$text-hover
        border-radius: 5px

    img
        width: 32px
        height: 32px
        border-radius: 50%
        margin-right: 12px

    div
        display: flex
        flex-direction: column

        .name
            font-size: variables.$font-medium

        .status
            font-size: variables.$font-small
