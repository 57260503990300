@use '../../variables'

.chatInputWrapper
    display: flex
    flex: 1
    width: 100%
    height: 70px
    padding: 0px 20px 24px

    .chatInputContainer
        display: flex
        align-items: center
        height: 45px
        width: 100%
        background-color: variables.$fourth-color
        border-radius: 8px

        .button
            display: flex
            align-items: center
            color: variables.$interactable-color
            cursor: pointer
            padding: 0px 16px

            &:hover
                color: variables.$interactable-hover

        .chatInput
            height: 100%
            width: 100%
            max-width: 100%
            font-size: variables.$font-medium
            background-color: inherit
            outline: none
            border: none
            caret-color: variables.$secondary-text-color
            color: variables.$secondary-text-color
