@use '../../../variables'

.container
    padding: 16px 32px
    height: 100%
    overflow-y: scroll

    .title
        text-transform: uppercase
        font-size: variables.$font-small
        color: variables.$text-color
        font-family: variables.$font

    .friendsContainer
        margin-top: 8px
