@use '../../../variables'

.container
    display: flex
    flex-direction: column
    background-color: variables.$secondary-color
    border-top-left-radius: 15px
    height: 100%

    .serverName
        display: flex
        align-items: center
        justify-content: space-between
        text-decoration: none
        color: variables.$white-color
        padding: 8px 16px
        cursor: pointer
        min-height: variables.$content-nav-height
        max-height: variables.$content-nav-height
        border-bottom: 2px solid variables.$navbar-color

        &:hover
            background-color: variables.$hover-color
            border-top-left-radius: 15px

        a
            text-decoration: none
            color: variables.$white-color
            font-weight: bold

    .channelContainer
        height: 100%
        color: variables.$text-color
        background-color: variables.$secondary-color
        padding: 5px 5px 5px 5px
        overflow-y: scroll
