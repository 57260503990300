@use './variables'

.container
    display: flex
    flex-direction: row
    height: 100%
    width: 100%

    .navbarContainer
        display: flex
        overflow-y: hidden

    .contentContainer
        display: flex
        width: calc( 100% - 65px )
        height: 100%

        .sidebarContainer
            width: 240px
            min-width: 240px
            max-width: 240px
            display: flex
            flex-direction: column

            .sidebar
                height: calc( 100vh - 52px)
                max-height: calc( 100vh - 52px)
                background-color: variables.$navbar-color

        .content
            width: 100%
            height: 100vh
