@use '../../variables'

.container
    display: flex
    flex-direction: column
    flex-grow: 1
    background-color: variables.$primary-color
    color: variables.$text-color
    width: 100%
    height: 100%
