@use '.././../variables'

.container
    display: flex
    flex-direction: column
    background-color: variables.$primary-color
    color: variables.$text-color
    width: 100%
    height: 100%
    max-height: 100vh

    .content
        display: flex
        flex-direction: row

        .chatContainer
            display: flex
            flex-direction: column
            height: 100%

            .chats
                display: flex
                flex-direction: column
                overflow-y: scroll
                height: 100%
                max-height: calc( 100vh - 115px)
                padding-bottom: 16px

        .memberContainer
            background-color: variables.$secondary-color
            min-width: 240px
            max-width: 240px
            height: 100%
            max-height: calc( 100vh - 45px)
            padding: 8px
            overflow-y: scroll

            .memberGroup
                padding: 0px 8px
                font-size: variables.$font-small
                color: variables.$text-color
                font-family: variables.$font
                margin: 8px 0px
