@use '../../../variables'

.container
    display: flex
    bottom: 0
    flex-direction: row
    padding: 0px 8px
    align-items: center
    background-color: variables.$third-color
    height: 52px

    img
        width: 32px
        height: 32px
        border-radius: 50%
        margin-right: 12px

    div

        .name
            font-size: variables.$font-medium
            color: variables.$white-color
            font-weight: bold

        .status
            font-size: variables.$font-small
            color: variables.$text-color

    .settingsBtn
        display: flex
        align-items: center
        margin-left: auto
        margin-right: 10px
        color: variables.$text-color
