@use '../../../variables'

.container
    display: flex
    flex-direction: row
    padding: 8px 16px
    height: variables.$content-nav-height
    font-size: variables.$font-medium
    border-bottom: 2px solid variables.$navbar-color

    .friendsContainer
        padding-right: 16px
        border-right: 1px variables.$divider-color solid
        display: flex
        align-items: center
        flex-direction: row
        color: white

        svg
            display: flex
            align-items: center
            color: variables.$text-color
            margin-right: 8px

    .statusContainer
        margin: 0px
        list-style: none
        display: flex
        flex-direction: row
        align-items: center
        padding: 0px

        li
            margin: 0px 15px
            font-size: variables.$font-medium

            &:hover
                background-color: variables.$third-hover-color
                border-radius: 5px

            a
                display: flex
                align-items: center
                justify-content: center
                padding: 5px 8px
                text-decoration: none
                color: variables.$text-color

                &:hover
                    color: variables.$text-hover

            &:last-child
                background-color: #43b581
                border-radius: 5px

                a
                    color: #FFF
                    padding: 5px 8px

        .active
            color: variables.$text-hover
            background-color: variables.$third-hover-color
            border-radius: 5px

        .activeAdd
            color: #3f9871
            background-color: #38524c
