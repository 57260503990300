@use '../../../../variables'

.channel
    display: flex
    align-items: center
    padding-left: 10px
    font-size: 16px
    cursor: pointer
    padding: 5px
    border-radius: 5px

    &:hover
        background-color: variables.$hover-color
        color: variables.$text-hover

    svg
        margin-right: 10px
