@use '../../variables'

$navbar-width: 75px

.navbar
    display: flex
    flex-direction: column
    width: $navbar-width
    height: 100%
    background-color: variables.$navbar-color
    align-items: center
    padding: 5px 7px 5px 0px

    .divider
        height: 2px
        width: 24px
        background-color: variables.$divider-color
        margin: 5px 0 5px 11px

    .serverContainer
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
